import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import LeftPanel from '@layout/Auth/LeftPanel.jsx'

import './Auth.scss'

export default function AuthLayout () {
  const [isLoggedIn] = useSelector(({ account }) => [account.isLoggedIn])

  const navigate = useNavigate()

  useEffect(() => {
    if (!isLoggedIn) {
      return
    }

    navigate('/')
  }, [isLoggedIn])

  if (isLoggedIn) {
    return null
  }

  return (
    <div className='auth-layout'>
      <LeftPanel>
        <Outlet />
      </LeftPanel>
    </div>
  )
}
