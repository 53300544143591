import React from 'react'
import shopBanner from './images/banners/shopexperts-banner.jpg'
import squareBanner from './images/banners/square-banner.jpg'
import downBlue from '@assets/icons/download-blue.svg'
import rectangularBanner from './images/banners/rectangular-banner.jpg'

import './Resources.scss'

export default function DownloadBanners () {
	return (
		<div className='p-resources-page__section__d-banner'>
			<div className='p-resources-page__section__d-banner__col'>
				<div className='p-resources-page__section__d-banner__row'>
					<div className='p-resources-page__section__d-banner__item'>
						<div className='p-resources-page__section__d-banner__sub-title'>
							shopexperts banner <span>(350x328)</span>
						</div>
						<a
							href={shopBanner}
							download='shopexperts-banner.jpg'
							className='p-resources-page__section__d-banner__download'
						>
							<img src={downBlue} alt='Download'/>
							Download
						</a>
					</div>
					<img
						className='p-resources-page__section__d-banner__sq-img'
						src={shopBanner} alt='Shop Banner'
					/>
				</div>
				<div className='p-resources-page__section__d-banner__row'>
					<div className='p-resources-page__section__d-banner__item'>
						<div className='p-resources-page__section__d-banner__sub-title'>
							square banner <span>(350x328)</span>
						</div>
						<a
							href={squareBanner}
							download='square-banner.jpg'
							className='p-resources-page__section__d-banner__download'
						>
							<img src={downBlue} alt='Download'/>
							Download
						</a>
					</div>
					<img
						className='p-resources-page__section__d-banner__sq-img'
						src={squareBanner} alt='Shop Banner'
					/>
				</div>
			</div>

			<div>
				<div className='p-resources-page__section__d-banner__item p-resources-page__section__d-banner__item--rect'>
					<div className='p-resources-page__section__d-banner__sub-title'>
						rectangular banner <span>(1200x127) </span>
					</div>
					<a
						href={rectangularBanner}
						download='rectangular-banner.jpg'
						className='p-resources-page__section__d-banner__download'
					>
						<img src={downBlue} alt='Download'/>
						Download
					</a>
				</div>

				<img
					className='p-resources-page__section__d-banner__rect-banner'
					src={rectangularBanner} alt='rectangular banner'
				/>

			</div>

		</div>
	)
}

