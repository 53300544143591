import React, { useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

import heycarsonLogo from '@assets/images/carson-logo.svg'
import paperclip from '@assets/icons/paper-clip-purple.svg'
import close from '@assets/icons/close-gray.svg'
import classnames from 'classnames'
import SelectInput from '@components/Inputs/SelectInput.jsx'
import axios from 'axios'
import { getRefApiUrl } from '@util/api.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import { useParams } from 'react-router-dom'

const initialFields = {
  name: '',
  email: '',
  shopify_url: '',
  description: '',
  timeframe: 'as soon as possible'
}
const timeframes = [
  'as soon as possible',
  'within 1 week',
  '1 month',
  'not sure'
]

export default function SubmitProjectLeft () {
  const fileRef = useRef(null)
  const [fields, setFields] = React.useState(initialFields)
  const [errors, setErrors] = useState({})
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  const params = useParams()

  const toastr = useToastr()

  async function handleSubmit (event) {
    event.preventDefault()

    setLoading(true)
    setErrors({})

    const data = new window.FormData()
    data.append('name', fields.name)
    data.append('email', fields.email)
    data.append('shopify_url', fields.shopify_url)
    data.append('description', fields.description)
    data.append('timeframe', fields.timeframe)

    files.forEach(file => {
      data.append('files[]', file.blob)
    })

    try {
      await axios.post(getRefApiUrl('v1', `/tasks:submit-form?referral_code=${params.ref_code}`), data)

      toastr({
        type: TOASTR_SUCCESS,
        title: 'Success',
        message: 'Your project has been submitted successfully.'
      })

      setFields(initialFields)
    } catch (error) {
      console.log('error submit form', error)
      let message = 'Please try again later.'

      if (error?.response?.status === 400 && error?.response?.data?.params) {
        message = error.response.data.message
        setErrors(error.response.data.params)
      }

      toastr({
        type: TOASTR_DANGER,
        title: 'Something went wrong',
        message: message
      })
    }

    setLoading(false)
  }

  function handleAttach (event) {
    event.preventDefault()

    fileRef.current.click()
  }

  function handleFileChange (event) {
    console.log(event.currentTarget.files[0])

    let name = event.currentTarget.files[0].name

    if (name.length > 15) {
      name = name.slice(0, 5) + '...' + name.slice(-10)
    }

    const file = {
      name: name,
      size: event.currentTarget.files[0].size,
      id: nanoid(),
      blob: event.currentTarget.files[0]
    }

    setFiles(files => [...files, file])
  }

  function handleFileRemove (fileId) {
    return event => {
      event.preventDefault()

      setFiles(files => files.filter(file => file.id !== fileId))
    }
  }

  return (
    <div className='submit-project-page__left'>
      <a className='submit-project-page__left__logo' href={import.meta.env.VITE_PARTNERS_URL} rel='noopener'>
        <img
          src={heycarsonLogo}
          alt='return to Shopexperts'
        />
      </a>

      <h2 className='submit-project-page__left__description'>
        Use this form to submit projects on your customers' behalf, when they reach out to you with design and
        development requests for their Shopify stores.
      </h2>

      <form action='#' onSubmit={handleSubmit} className='submit-project-page__form'>
        <TextInput
          labelClassName='submit-project-page__input-label'
          required
          label="Your customer's name"
          placeholder='Julie Appleseed'
          value={fields.name}
          onChange={event => setFields({ ...fields, name: event.currentTarget.value })}
          error={errors.name}
          subText={errors.name}
        />

        <TextInput
          labelClassName='submit-project-page__input-label'
          required
          label="Your customer's email"
          placeholder='julie@example.com'
          value={fields.email}
          onChange={event => setFields({ ...fields, email: event.currentTarget.value })}
          error={errors.email}
          subText={errors.email}
        />

        <TextInput
          labelClassName='submit-project-page__input-label'
          required
          label="Your customer's Shopify store URL"
          placeholder='https://happytoes.com'
          value={fields.shopify_url}
          onChange={event => setFields({ ...fields, shopify_url: event.currentTarget.value })}
          error={errors.shopify_url}
          subText={errors.shopify_url}
        />

        <TextInput
          labelClassName='submit-project-page__input-label submit-project-page__input-label--attach'
          required
          multiline={3}
          label='Write a detailed project brief below.'
          placeholder="Write a project brief here. Share more about what your client needs help with, or feel free to copy & paste the original support ticket and we'll sort it out from there."
          value={fields.description}
          onChange={event => setFields({ ...fields, description: event.currentTarget.value })}
          error={errors.description}
        />

        <input ref={fileRef} type='file' onChange={handleFileChange} style={{ display: 'none' }} />
        <a href='#' className='submit-project-page__attach' onClick={handleAttach}>
          <div className='submit-project-page__attach__button'>
            <img className='submit-project-page__attach__icon' src={paperclip} alt='' />
            <span className='submit-project-page__attach__text'>Attach files</span>
          </div>
        </a>

        <div
          className={classnames('submit-project-page__files', {
            'submit-project-page__files--has-files': files.length > 0
          })}
        >
          {files.map(file => (
            <div key={file.id} className='submit-project-page__file'>
              <span className='submit-project-page__file__name'>{file.name}</span>
              <a href='#' className='submit-project-page__file__remove' onClick={handleFileRemove(file.id)}>
                <img src={close} alt='' />
              </a>
            </div>
          ))}
        </div>

        <SelectInput
          labelClassName='submit-project-page__input-label'
          required
          label='When do they need this?'
          placeholder='as soon as possible'
          value={fields.timeframe}
          onChange={value => setFields({ ...fields, timeframe: value })}
          options={timeframes.map(t => ({
            key: t,
            label: t,
            value: t
          }))}
          error={errors.timeframe}
          subText={errors.timeframe}
        />

        <div className='submit-project-page__actions'>
          <Button
            disabled={loading}
            className='submit-project-page__submit'
            type='submit' primary
            onClick={handleSubmit}
          >
            Submit project
          </Button>
        </div>
      </form>
    </div>
  )
}
