import formatNumberWithCommas from '@util/formatNumberWithComas.js'

export const getSymbol = curr => {
  switch (curr) {
    case 'USD':
      return '$'
    default:
      return ''
  }
}

export const shortAmount = (v, currency) => {
  let k = 'k'

  if (v >= 10000) {
    v = v / 1000
  } else {
    k = ''
  }

  return formatAmount(v, currency) + k
}

export const formatAmount = (amount, currency, fraction = 2) => {
  const symbol = getSymbol(currency)
  let a = Number(amount)
  let sign = ''

  if (a < 0) {
    sign = '-'
  }

  a = Math.abs(a).toFixed(fraction)

  return `${sign}${symbol}${formatNumberWithCommas(a)}`
}
