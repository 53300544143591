import React from 'react'

import ShadowBox from '@components/ShadowBox/index.jsx'

import examples from './examples.jsx'
import arrow from '@assets/icons/arrows/arrow-up-right.svg'
import Button from '@components/Button/index.jsx'

export default function ExampleSection () {
  return (
    <section className='p-resources-page__section'>
        <div className='p-resources-page__section__sub-title'>A few examples</div>
      <div className='p-resources-page__section__description'>
        We value partnerships like these ones, and we go out our way everyday as a team to make sure we drive as
        much or more value back toward our partners.
      </div>

      <div className='p-resources-page__section__split' />

      <div className='p-resources-page__examples'>
        {examples.map(ex => (
          <ShadowBox key={ex.title} className='p-resources-page__example'>
            <div className='p-resources-page__example__logo'>
              <img src={ex.image} alt={ex.title} />
            </div>

            <div className='p-resources-page__example__desc'>
              {ex.description}
            </div>
            <a
              href={ex.link}
              target='_blank'
              className='p-resources-page__example__link'
              rel='noreferrer noopener'
            >
              <Button
                white small
                className='main-sidebar__item__aff-btn'
              >
                Read Article
                <img src={arrow} alt='copy affiliate link' />
              </Button>
            </a>
          </ShadowBox>
        ))}
      </div>
    </section>
  )
}
