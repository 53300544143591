import React from 'react'
import classnames from 'classnames'

import purpleStar from '@assets/icons/purple-star.svg'
import grayStar from '@assets/icons/gray-star.svg'

export default function LevelsTooltip ({ levels, currentLevelSlug, numLevel, openTooltip }) {
  if (!currentLevelSlug) {
    return
  }
  function levelSubTitle (currentLevel, mappedLevel) {
    if (currentLevel === mappedLevel) {
      return 'Current Level'
    } else if (mappedLevel < currentLevel) {
      return 'Unlocked'
    } else {
      return 'Next'
    }
  }

  return (
    <div className={classnames('overview-page__tooltip-content', {
      'overview-page__tooltip-content--show': openTooltip
    })}
    >
      {levels.map((item) => (
        <div
          key={item.number}
          className={classnames('overview-page__tooltip-content__row', {
            'overview-page__tooltip-content__row--active': currentLevelSlug === item.slug
          })}
        >
          {currentLevelSlug === item.slug && (
            <div className='overview-page__tooltip-content__current'>
              CURRENT LEVEL
            </div>
          )}

          <div className='overview-page__tooltip-content__desc-container'>
            <div className='overview-page__tooltip-content__special-container'>
              <div className='overview-page__tooltip-content__col-title'>
                {item.name}
              </div>
            </div>
            <div className='overview-page__tooltip-content__col-desc'>
              {item.text}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
