import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SignUPLeftPanel from '@layout/Auth/SignUPLeftPanel.jsx'
import SignUpRightPanel from '@layout/Auth/SignUpRightPanel.jsx'

import './signup.scss'

export default function SignupLayout () {
	const [isLoggedIn] = useSelector(({ account }) => [account.isLoggedIn])

	const navigate = useNavigate()

	useEffect(() => {
		if (!isLoggedIn) {
			return
		}

		navigate('/')
	}, [isLoggedIn])

	if (isLoggedIn) {
		return null
	}

	return (
		<div className='signup-layout'>
			<SignUPLeftPanel />
			<SignUpRightPanel />
    </div>
	)
}
