import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import { useSearchParams } from 'react-router-dom'

import { formatAmount } from '@util/currency.js'
import { loadConversions } from '@redux/referrals/conversions.js'
import { getCommissionInfo, getConversionStatus } from '@util/referrals.js'

import ListRow from '@components/ListRow/index.jsx'
import ListRowHeader from '@components/ListRow/Header.jsx'
import ListRowItem from '@components/ListRow/Item.jsx'
import ListRowColumn from '@components/ListRow/Column.jsx'
import CursorPagination from '@components/Pagination/Cursor.jsx'

import './ConversionList.scss'
import formatNumberWithCommas from '@util/formatNumberWithComas.js'

export default function ConversionList ({ pageLimit, hash, showPagination = true }) {
  const [params, setParams] = useSearchParams()
  const [isEmpty, setIsEmpty] = useState(false)

  const direction = params.get('direction') || 'next'
  const before = params.get('before')
  const after = params.get('after')

  const [
    loading, conversions,
    totalResults, remainingResults
  ] = useSelector(({ referrals: { conversions } }) => [
    conversions.loading,
    conversions.data,
    conversions.totalResults,
    conversions.remainingResults
  ])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      loadConversions({
        before,
        after,
        limit: pageLimit,
        withCustomer: true
      })
    )
      .then(({ totalResults }) => {
        if (!totalResults) {
          setIsEmpty(true)
        } else {
          setIsEmpty(false)
        }
      })
      .catch(error => {
        console.error('conversion list error', error)
      })
  }, [before, after, pageLimit])

  async function handlePageChange ({ before, after, direction }) {
    const params = {}

    if (before) {
      params.before = before
    }
    if (after) {
      params.after = after
    }
    if (direction) {
      params.direction = direction
    }

    setParams(params)
  }

  const backgroundColors = ['#BBA9FB', '#9DC899', '#A0C5FD', '#FCC89A', '#95DAED']

  function getInitials (name) {
    const parts = name.split(' ')
    if (parts.length > 1) {
      return parts[0][0] + parts[1][0]
    } else {
      return parts[0].slice(0, 2)
    }
  }

  return (
    <div className='pd-conversion-shared__list-container'>
      <ListRow
        loading={loading}
        empty={isEmpty}
        className='pd-conversion-shared__list'
        headers={(
          <>
            <ListRowHeader
              className='pd-conversion-shared__list__referral pd-conversion-shared__list__referral--tab'
            >
              Client
            </ListRowHeader>
            <ListRowHeader
              className='pd-conversion-shared__list__purchase pd-conversion-shared__list__purchase--tab'
            >
              Purchase
            </ListRowHeader>
            <ListRowHeader
              className='pd-conversion-shared__list__commission pd-conversion-shared__list__commission--tab'
            >
              Commission
            </ListRowHeader>
            <ListRowHeader
              className='pd-conversion-shared__list__date pd-conversion-shared__list__date--tab'
            >
              Conversion Date
            </ListRowHeader>
            <div>
              <div className='pd-conversion-shared__list__most-recent'>
                Most recent referrals
              </div>
            </div>
          </>
        )}
        items={conversions.map((conversion) => {
          const initials = getInitials(conversion.customer.name)
          const bgColor = backgroundColors[Math.floor(Math.random() * backgroundColors.length)]
          return (
            <ListRowItem key={conversion.id}>
              <ListRowColumn className='pd-conversion-shared__list__referral'>
                {conversion.customer && (
                  <div className='pd-conversion-shared__list__profile'>
                    <div
                      style={{ backgroundColor: bgColor }}
                      className='pd-conversion-shared__list__profile__avatar'
                    >
                      <div className='pd-conversion-shared__list__profile__initial'>
                        {initials.toUpperCase()}
                      </div>
                    </div>
                    <div>
                      {conversion.customer.name}
                      <br />
                      <span>
                        {conversion.customer.email}
                      </span>
                    </div>
                  </div>
                )}
              </ListRowColumn>
              <ListRowColumn className='pd-conversion-shared__list__purchase'>
                <div className='pd-conversion-shared__list__mobile-tab'>Purchase:&nbsp;</div>
                {formatAmount(conversion.amount, conversion.currency)}
              </ListRowColumn>
              <ListRowColumn className='pd-conversion-shared__list__commission'>
                <div className='pd-conversion-shared__list__commission__col'>
                  <div className='pd-conversion-shared__list__mobile-tab'>Commission:&nbsp;</div>
                </div>
                {formatAmount(conversion.commission, conversion.currency)} ({getCommissionInfo(conversion, conversion.currency || 'USD')})&nbsp;
              </ListRowColumn>
              <ListRowColumn className='pd-conversion-shared__list__date pd-conversion-shared__list__date'>
                <div className='pd-conversion-shared__list__mobile-tab'>Conversion date:&nbsp;</div>
                {DateTime.fromISO(conversion.created_at).toFormat('dd MMM, y')}&nbsp;
                <br />
                <span
                  className={`pd-conversion-shared__list__status pd-conversion-shared__list__status--${conversion.status} pd-conversion-shared__list__status--desktop`
                }>
                  {getConversionStatus(conversion.status)}
                </span>
              </ListRowColumn>

              <div
                className={`pd-conversion-shared__list__status pd-conversion-shared__list__status--${conversion.status} pd-conversion-shared__list__status--mobile`
                }>
                {getConversionStatus(conversion.status)}
              </div>
            </ListRowItem>
          )
        })}
      />

      {showPagination && (
        <CursorPagination
          direction={direction}
          cursor='created_at'
          showDetails
          items={conversions}
          onPageChange={handlePageChange}
          pageLimit={pageLimit}
          totalResults={totalResults}
          remainingResults={remainingResults}
        />
      )}
    </div>
  )
}

ConversionList.propTypes = {
  pageLimit: PropTypes.number,
  hash: PropTypes.string,
  showPagination: PropTypes.bool
}
