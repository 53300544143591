export const themeNotifications = [{
  name: 'themes_review_published',
  title: 'New theme review',
  description: 'Get notified when a new review is published for one of your themes.'
}]

export const appNotifications = [{
  name: 'apps_review_published',
  title: 'New app review',
  description: 'Get notified when a new review is published for one of your apps.'
}]

export default [{
  name: 'conversions_new_referral',
  title: 'New referral signup',
  description: 'Get notified when one of your referred customers signs up with Shopexperts through your referral link.'
}, {
  name: 'conversions_pending',
  title: 'Referral payment',
  description: 'Get notified when one of your referred customers places a payment with Shopexperts.'
}, {
  name: 'conversions_approved',
  title: 'Commission approval',
  description: 'Get notified when we approve commissions for your referred customers\' projects.'
}, {
  name: 'withdrawal_approved',
  title: 'Withdrawal request processed',
  description: 'Get notified when we\'ve released your pending commission following your withdrawal request from your balance.'
}]
