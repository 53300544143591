import React from 'react'

import signUpSubmitted from '@assets/images/sign-up-submitted.jpg'
import Button from '@components/Button/index.jsx'
import arrowBack from '@assets/icons/arrows/arrow-left-black.svg'
import { Link } from 'react-router-dom'

function SignupSubmitted () {
	return (
		<div className='partners-signup__right__submitted'>
			<img
				className='partners-signup__right__submitted__img'
				src={signUpSubmitted} alt='Sign up submitted'
			/>
			<p className='partners-signup__right__submitted__desc'>
				Our team will thoroughly review your application details. We're excited about
				the possibility of welcoming you into our pool of partners. Thanks for your patience!
			</p>

			<Link to='https://shopexperts.com/' className='partners-signup__right__submitted__login'>
				<Button
					white
					small
					expanded
					className='partners-signup__right__submitted__btn'
				>
					<img src={arrowBack} alt='Back to website' />
					Back to Website
				</Button>
			</Link>

		</div>
	)
}

export default SignupSubmitted
