import React from 'react'

import downloadGreen from '@assets/icons/download-green.svg'
import downloadGray from '@assets/icons/download-gray.svg'


export default function AddBanner () {
	return (
		<div className='p-resources-page__section'>
			<div className='p-resources-page__section__add'>
				<div className='p-resources-page__section__header__content'>
					 <h1 className='p-resources-page__section__header__title'>
						Add our banner and&nbsp;
						 <span className='p-resources-page__section__header__title__name'>start earning today.</span>
					</h1>
					<p className='p-resources-page__section__header__desc'>
						We value partnerships like these ones, and we go out our way everyday as a
						team to make sure we drive as much or more value back toward our partners.
					</p>

					<div className='p-resources-page__section__add__cta'>
						<a
							href='./images/banners/shopexperts-banners.zip'
							className='p-resources-page__section__add__cta__link p-resources-page__section__add__cta__link--green'
							download="shopexperts-banners.zip"
						>
							<img src={downloadGreen} alt='Download'/>
							Download shopexperts banners
						</a>
						{/*<a*/}
						{/*	href='https://shopexperts.com/wp-content/uploads/2021/05/shopexperts-banner.png'*/}
						{/*	download*/}
						{/*	className='p-resources-page__section__add__cta__link p-resources-page__section__add__cta__link--gray'*/}
						{/*>*/}
						{/*	<img src={downloadGray} alt='Download'/>*/}
						{/*	Download shopexperts brand assets*/}
						{/*</a>*/}
					</div>

					<p className='p-resources-page__section__add__desc'>
						Do you have a custom banner design in mind?<br/>
						Get in touch at&nbsp;
						<a href='mailto:partners@shopexperts.com'>
							partners@shopexperts.com
						</a>
						&nbsp;and our expert design team will address your needs.
					</p>
				</div>
			</div>
		</div>
	)
}
