import React from 'react'
import classnames from 'classnames'

import { useBackToLogin, useBackToWebsite } from '@layout/Auth/hooks.js'

import { Link } from 'react-router-dom'

import heycarsonLogo from '@assets/images/shologo-formy-heycarson.svg'
import back from '@assets/icons/back-circle.svg'

function LeftPanel (props) {
  const [showWebsite] = useBackToWebsite()
  const [showLogin] = useBackToLogin()

  return (
    <div className='partners-auth__left'>
      <div className='partners-auth__left__content'>
        <div>
          <a href={import.meta.env.VITE_SITE_URL} rel='noopener'>
            <img
              className='partners-auth__left__logo'
              src={heycarsonLogo}
              alt='return to HeyCarson'
            />
          </a>
        </div>

        <div className=''>
          <Link
            to='/login'
            className={classnames('partners-auth__left__back', {
              'partners-auth__left__back--show': showLogin
            })}
          >
            <img src={back} alt='Back to website' />
            Back to login
          </Link>
          {props.children}
        </div>
      </div>
    </div>
  )
}

export default LeftPanel
