import React from 'react'

import ex1 from './images/ex_shoppad.png'
import ex2 from './images/ex_troop.png'
import ex3 from './images/ex_switch.png'
import ex4 from './images/ex_archetype.png'
import ex5 from './images/ex_judge.png'
import ex6 from './images/ex_shopify.png'

export default [{
  image: ex1,
  title: 'ShopPad',
  description: (
    <p className='p-resources-page__example__description'>
      <a href='https://www.linkedin.com/company/shoppad/' target='_blank' rel='noopener noreferrer'>
        ShopPad Inc.
      </a>
      &nbsp;mentions HeyCarson as a help resource on the demo site for their Infinite Options app.
    </p>
  ),
  link: 'https://demo-shoppad.myshopify.com/products/raglan-baseball-shirt'
}, {
  image: ex2,
  title: 'Troop Themes',
  description: (
    <p className='p-resources-page__example__description'>
      <a href='https://www.linkedin.com/company/troop-themes/' target='_blank' rel='noopener noreferrer'>
        Troop Themes
      </a>
      &nbsp;includes HeyCarson in a small list of preferred customization partners in their support
    </p>
  ),
  link: 'https://support.troopthemes.com/support/tweaks-customizations/'

}, {
  image: ex3,
  title: 'Switch Themes',
  description: (
    <p className='p-resources-page__example__description'>
      Switch Themes includes HeyCarson as part of a small list of preferred customization partners in their support
    </p>
  ),
  link: 'https://switchthemes.co/support/theme-customisation/'
}, {
  image: ex4,
  title: 'Archetype Themes',
  description: (
    <p className='p-resources-page__example__description'>
      <a href='https://www.linkedin.com/company/troop-themes/' target='_blank' rel='noopener noreferrer'>
        Archetype Themes
      </a>
      &nbsp;includes us as part of a short list of preferred customization partners in their support section.&nbsp;

    </p>
  ),
  link: 'https://archetypethemes.co/pages/partners'
}, {
  image: ex5,
  title: 'Judge.me',
  description: (
    <p className='p-resources-page__example__description'>
      <a href='https://www.linkedin.com/company/judgeme/' target='_blank' rel='noopener noreferrer'>
        Judge.me
      </a>
      &nbsp;support includes a link to HeyCarson in their support emails which direct complex customizations to us.
    </p>
  ),
  link: 'https://support.judge.me/support/solutions/articles/44002124891-how-to-install-judgeme-widgets-on-a-custom-theme'
}, {
  image: ex6,
  title: 'Shopify',
  description: (
    <p className='p-resources-page__example__description'>
      <a href='https://www.linkedin.com/company/shopify/' target='_blank' rel='noopener noreferrer'>
        Shopify
      </a>
      &nbsp;directs standard and custom SPI banner implementation requests to us exclusively&nbsp;
    </p>
  ),
  link: 'https://help.shopify.com/en/manual/online-store/os/using-themes/change-the-layout/feature-customization#feature-customization'
}]
