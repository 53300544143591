import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import classnames from 'classnames'

import { shortAmount } from '@util/currency.js'
import { getWithdrawalStatus } from '@util/referrals.js'
import { loadPartnerBalance } from '@redux/referrals/dashboard.js'
import { loadBalance, loadWithdrawalList, toggleAffiliateModal } from '@redux/referrals/withdrawals.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

import CursorPagination from '@components/Pagination/Cursor.jsx'
import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'
import ShadowBox from '@components/ShadowBox/index.jsx'

import paypal from '@assets/icons/paypal.svg'
import trendUp from '@assets/icons/trend-up.svg'
import { updatePartner } from '@redux/referrals/partners.js'
import formatNumberWithCommas from '@util/formatNumberWithComas.js'

const pageLimit = 6
const initialFields = {
  paypal: ''
}

export default function Withdrawals () {
  const [balance, setBalance] = useState('0.00')
  const [commission, setCommission] = useState('0.00')
  const [sales, setSales] = useState('0.00')
  const [canWithdraw, setCanWithdraw] = useState(false)
  const [dates, setDates] = useState({ before: null, after: null })
  const [fields, setFields] = useState(initialFields)

  const [
    withdrawals,
    loading,
    total,
    remaining,
    partnerStats,
    balanceStat,
    partner
  ] = useSelector(({ referrals: { withdrawals, dashboard, partners } }) => [
    withdrawals.withdrawals.data,
    withdrawals.withdrawals.loading,
    withdrawals.withdrawals.total,
    withdrawals.withdrawals.remaining,
    dashboard.partnerBalance.data,
    withdrawals.balance,
    partners.partner.data
  ])

  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    dispatch(loadBalance())
      .catch(error => {
        console.log('balance error', error)
        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message: 'Failed to load balance'
        })
      })

    dispatch(loadPartnerBalance({
      start: undefined,
      end: undefined
    }))
      .catch(error => {
        console.log('commissions summary error', error)
        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message: 'Failed to commissions summary'
        })
      })
  }, [])
  useEffect(() => {
    const stats = partnerStats.reduce((acc, current) => {
      if (
        current.name === 'conversion_commission' ||
        current.name === 'conversion_amount'
      ) {
        acc[current.name] = shortAmount(Number(current.value) || 0, 'USD')
      }

      if (current.name === 'partner_balance') {
        acc.can_withdraw = Number(current.value) > 0
      }

      return acc
    }, {})

    setCanWithdraw(stats.can_withdraw)
    setBalance(balanceStat)
    setCommission(stats.conversion_commission)
    setSales(stats.conversion_amount)
  }, [partnerStats, balanceStat])

  useEffect(() => {
    dispatch(loadWithdrawalList({
      limit: pageLimit,
      before: dates.before,
      after: dates.after
    }))
      .then(() => {})
      .catch(error => {
        console.log('withdrawals list error', error)
        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message: 'Failed to load withdrawals list'
        })
      })
  }, [dates.before, dates.after])

  // partner is loaded at ./UserInfo.jsx
  useEffect(() => {
    setFields(old => ({ ...old, paypal: partner?.paypal_email || '' }))
  }, [partner?.id, partner?.updated_at])

  function handlePageChange ({ before, after }) {
    return new Promise(resolve => {
      setDates({ before, after })
      resolve()
    })
  }

  function handleClickWithdraw () {
    dispatch(toggleAffiliateModal(true))
  }

  function handleClickSave () {
    if (!fields.paypal) {
      return
    }

    dispatch(updatePartner({
      id: partner.id,
      ...partner,
      paypal_email: fields.paypal
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Payout method updated successfully.'
        })
      })
      .catch(error => {
        let message = 'Please try again later.'

        if (
          error?.response?.status === 400 &&
          error?.response?.data?.message
        ) {
          message = error.response.data.message
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong',
          message
        })
        console.log('payment method error:', error)
      })
  }

  return (
    <div className='settings_page__section settings_page__withdrawals'>
      <div className='settings_page__section__header'>
        <div className='settings_page__section__title'>Withdrawals</div>

        <div className='settings_page__section__action'>
          <Button small primary hollow onClick={handleClickSave}>
            Save
          </Button>
        </div>
      </div>

      <ShadowBox>
        <div className='settings_page__section__body'>
          <div className='settings_page__withdrawals__pm'>
            <div className='settings_page__withdrawals__pm__name'>
              Paypal
            </div>
            <div className='settings_page__withdrawals__pm__desc'>
              Introduce the email address associated with your PayPal account.
            </div>

            <div className='settings_page__withdrawals__pm__input-container'>
              <div className='settings_page__withdrawals__pm__logo'>
                <img src={paypal} alt='PayPal' />
              </div>
              <TextInput
                labelClassName='settings_page__withdrawals__pm__input-label'
                small
                placeholder='PayPal Email'
                value={fields.paypal}
                onChange={event => setFields(old => ({ ...old, paypal: event.target.value }))}
              />
            </div>
          </div>

          <div className='settings_page__withdrawals__separator' />

          <div className='settings_page__withdrawals__summaries'>
            <div className='settings_page__withdrawals__summary'>
              <div className='settings_page__withdrawals__summary__name'>
                Commissions earned
              </div>
              <div className='settings_page__withdrawals__summary__value'>
                {commission}
              </div>
            </div>
            <div className='settings_page__withdrawals__summary'>
              <div className='settings_page__withdrawals__summary__name'>
                Referred sales
              </div>
              <div className='settings_page__withdrawals__summary__value'>
                {sales}
              </div>
            </div>
          </div>

          <div className='settings_page__withdrawals__headers'>
            <div className='settings_page__withdrawals__header'>
              Date
            </div>
            <div className='settings_page__withdrawals__header'>
              Amount
            </div>
          </div>

          <div
            className={classnames('settings_page__withdrawals__items', {
              'settings_page__withdrawals__items--has-items': total > 0
            })}
          >
            {withdrawals.map(withdrawal => (
              <div key={withdrawal.id} className='settings_page__withdrawals__item'>
                <div className='settings_page__withdrawals__item__date'>
                  {DateTime.fromISO(withdrawal.created_at).toFormat('dd LLL yyyy')}&nbsp;
                  <span className='settings_page__withdrawals__item__status'>
                    {getWithdrawalStatus(withdrawal.status)}
                  </span>
                </div>
                <div className='settings_page__withdrawals__item__amount'>
                  {shortAmount(withdrawal.commission, 'USD')}
                </div>
              </div>
            ))}

            {!loading && total === 0 && (
              <div className='settings_page__withdrawals__item'>
                <div className='settings_page__withdrawals__item__date'>
                  Your withdrawals request will be here
                </div>
              </div>
            )}
          </div>

          {withdrawals.length > 0 && (
            <CursorPagination
              direction='next'
              items={withdrawals}
              cursor='created_at'
              onPageChange={handlePageChange}
              showDetails
              pageLimit={pageLimit}
              totalResults={total}
              remainingResults={remaining}
            />
          )}

          <div className='settings_page__withdrawals__separator' />

          <div className='settings_page__withdrawals__balance'>
            <div className='partners__settings__balance__info'>
              <div className='settings_page__withdrawals__balance__title'>
                AVAILABLE BALANCE
              </div>
              <div className='settings_page__withdrawals__balance__amount'>
                {formatNumberWithCommas(balance)}
              </div>
            </div>

            <div className='settings_page__withdrawals__balance__action'>
              <Button
                dark
                disabled={!canWithdraw}
                onClick={handleClickWithdraw}
              >
                <img
                  className='settings_page__withdrawals__balance__icon'
                  src={trendUp}
                  alt='withdrawal'
                />
                Withdraw
              </Button>
            </div>
          </div>
        </div>
      </ShadowBox>
    </div>
  )
}
