import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'

import { useBackToLogin, useBackToWebsite } from '@layout/Auth/hooks.js'
import { forgotPassword } from '@redux/account.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

import letter from '@assets/images/letter.svg'

import './ResetPassword.scss'

function PartnersResetPassword () {
  const [email, setEmail] = useState('')
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)
  const [, setBackToWebsite] = useBackToWebsite()
  const [, setBackLogin] = useBackToLogin()

  const [loading] = useSelector(({ account }) => [account.authPending])

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    setBackLogin(true)
    setBackToWebsite(false)

    return () => {
      setEmail('')
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault && event.preventDefault()

    if (!email) {
      return
    }

    dispatch(forgotPassword({
      email
    }))
      .then(() => {
        setSubmitted(true)
        setBackToWebsite(false)

        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'Please check your email inbox to reset your password.'
        })
      })
      .catch(error => {
        let message = 'Please try again later.'

        if (
          error?.response?.status === 400 &&
          error?.response?.data?.params
        ) {
          message = error.response.data.message

          setErrors({
            email: error.response.data.params.email
          })
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message
        })
      })
  }

  return (
    <div className='reset-password'>
      <div className={classnames('reset-password__header', {
        'reset-password__header--submitted': submitted
      })}
      >
        <h1 className='reset-password__title'>
          Password reset
        </h1>
        <h3 className='reset-password__desc'>
          Enter the email associated with your account and we’ll
          send instructions to reset your password.
        </h3>
        <form
          action='#'
          className='reset-password__form'
          onSubmit={handleSubmit}
        >
          <div className='reset-password__form-group'>
            <TextInput
              label='Email address'
              placeholder='you@example.com'
              type='email'
              small
              value={email}
              onChange={event => setEmail(event.target.value)}
              error={errors.email}
              subText={errors.email}
            />
          </div>

          <Button
            type='submit'
            dark
            small
            expanded
            className='reset-password__link'
            disabled={loading}
          >
            Send password reset link
          </Button>
        </form>
      </div>

      {submitted && (
        <div className='reset-password__success'>
          <img
            className='reset-password__success__img'
            src={letter}
            alt='Email sent successfully'
          />
          <h1 className='reset-password__success__title'>
            We've sent you a link
          </h1>
          <h3 className='reset-password__success__desc'>
            Please check your inbox, including the spam folder for our password reset link.
          </h3>
          <Link
            to='/password/reset'
            onClick={handleSubmit}
            className='reset-password__success__resend'
          >
            Resend password reset email
          </Link>
        </div>
      )}
    </div>
  )
}

export default PartnersResetPassword
