import React, { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { shortAmount } from '@util/currency.js'
import { partnerLevels } from '@util/referrals.js'
import {
  loadBalance,
  toggleAffiliateModal
} from '@redux/referrals/withdrawals.js'
import { loadPartnerBalance } from '@redux/referrals/dashboard.js'
import { TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import LevelsTooltip from '@pages/Partners/Partner/Overview/LevelsTooltip.jsx'

import InfoCard from '@components/InfoCard/index.jsx'
import formatNumberWithCommas from '@util/formatNumberWithComas.js'

import Button from '@components/Button/index.jsx'
import questionMark from '@assets/icons/question-circle-light.svg'
import loadingLevel from '@assets/icons/partner-level-1.svg'
import level1Purple from '@assets/icons/partner-level-1-purple.svg'
import level2Purple from '@assets/icons/partner-level-2-purple.svg'
import level3Purple from '@assets/icons/partner-level-3-purple.svg'
import level4Purple from '@assets/icons/partner-level-4-purple.svg'
import copyLink from '@assets/icons/copy-link-black.svg'
import linkGreen from '@assets/icons/link-green.svg'
import withdrawIcon from '@assets/icons/withdraw.svg'
import PropTypes from 'prop-types'

function getStats (stats) {
  return stats.reduce((acc, current) => {
    if (
      current.name === 'commission_pending' ||
      current.name === 'conversion_commission' ||
      current.name === 'conversion_amount' ||
      current.name === 'partner_balance'
    ) {
      acc[current.name] = shortAmount(Number(current.value) || 0, 'USD')
    }

    return acc
  }, {})
}

function getPartnerLevel (amount) {
  const current = partnerLevels.reduce((acc, level) => {
    if (!acc) {
      return level
    }

    if (amount >= level.requirement && acc.requirement < level.requirement) {
      return level
    }

    return acc
  }, null)

  if (!current) {
    return null
  }

  const next = partnerLevels.find(l => l.requirement > current?.requirement) || current

  return {
    current,
    next
  }
}

function OverviewHero (props) {
  const [userName, codes, width] = useSelector((
    { account, referrals: { refCodes }, viewport }
  ) => [account.user?.name, refCodes.data, viewport.width])

  const [refLink, setRefLink] = useState('')
  const [copied, setCopied] = useState(false)
  const [level, setLevel] = useState(null)
  const [totalReferred, setTotalReferred] = useState(0.00)
  const [commission, setCommission] = useState(shortAmount(0, 'USD'))
  const [openTooltip, setOpenTooltip] = useState(false)

  const copiedTimeout = useRef(null)
  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    return () => {
      clearTimeout(copiedTimeout.current)
    }
  }, [])

  useEffect(() => {
    props.loadBalance()
  }, [])


  useEffect(() => {
    dispatch(
      loadPartnerBalance({
        start: undefined,
        end: undefined
      })
    )
      .then(({ stats, tieredAmount }) => {
        const parsedStats = getStats(stats)

        setCommission(parsedStats.commission_pending)
        setTotalReferred(stats.find(s => s.name === 'conversion_amount')?.value || 0)
        setLevel(getPartnerLevel(tieredAmount))
      })
      .catch(error => {
        console.log('dash stats error', error)
      })
  }, [])

  useEffect(() => {
    if (!codes?.length || !codes[0]?.custom_code) {
      return
    }

    setRefLink(`${import.meta.env.VITE_SITE_URL}/client/new-project/?ref=${codes[0]?.custom_code}`)
  }, [codes])


  function convertToK (num) {
    if (num >= 1000) {
      return (num / 1000).toFixed(1) + 'k'
    } else {
      return num.toString()
    }
  }

  const numLevel = Number(level?.current?.slug.replace('level-', ''))

  function levelObject () {
    let progress = 0
    if (numLevel === 1) {
      progress = ((Number(totalReferred) / 15000) * 100) + '%'
      return {
        image: level1Purple,
        maxAmount: '$15,000',
        progressBar: progress
      }
    } else if (numLevel === 2) {
      progress = ((Number(totalReferred) / 25000) * 100) + '%'
      return {
        image: level2Purple,
        maxAmount: '$25,000',
        progressBar: progress
      }
    } else if (numLevel === 3) {
      progress = ((Number(totalReferred) / 50000) * 100) + '%'
      return {
        image: level3Purple,
        maxAmount: '$50,000',
        progressBar: progress
      }
    } else if (numLevel === 4) {
      return {
        image: level4Purple,
        maxAmount: '',
        progressBar: '100%'
      }
    }
    return {
      image: loadingLevel,
      maxAmount: '$0',
      progressBar: '0%'
    }
  }

  async function handleClickRefLink () {
    clearTimeout(copiedTimeout.current)
    try {
      console.log('navigator', window.navigator)
      if (window.navigator.clipboard) {
        await window.navigator.clipboard.writeText(refLink)
      } else {
        const tempInput = document.createElement("input");
        tempInput.value = refLink;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        console.log('copied using input')
      }

      setCopied(true)
      toastr({
        type: TOASTR_SUCCESS,
        title: 'Success',
        message: 'Affiliate link copied to clipboard'
      })

      copiedTimeout.current = setTimeout(() => {
        setCopied(false)
      }, 500)
    } catch (error) {
      console.log('navigator error', error)
    }
  }

  const canWithdraw = props.balance > 0


  const levelObj = levelObject()
  return (
    <div className='overview-page__hero'>
      <div className='overview-page__top'>
        <div className='overview-page__top__content'>
           <h1 className='overview-page__title'>
            Welcome back,&nbsp;
             <span className='overview-page__title__name'>{userName.trim() + '!'}</span>
          </h1>
          <p className='overview-page__desc'>
            Here is an overview of your shopexperts partner account.
          </p>
        </div>
        <div className='overview-page__aff'>
          <div className='overview-page__aff__ref'>
            {refLink}
          </div>
           <Button
             white={width > 1200}
             dark={width < 1200}
             expanded={width < 500}
             small
             className='overview-page__aff__btn'
             onClick={handleClickRefLink}
           >
            <img src={width > 1200 ? copyLink : linkGreen} alt='copy affiliate link' />
            Copy Affiliate Link
          </Button>

        </div>
      </div>
      <div className='overview-page__levels-stats'>

        <InfoCard
          className='overview-page__card overview-page__commissions'
          toolTip={(
            <span className='pdash__hero__report__tooltip'>
              Total available for withdrawal
            </span>
          )}
          customContent={(
            <div className='overview-page__card__balance'>
              <div className='overview-page__card__balance__desc'>
                Your balance
              </div>
              <div className='overview-page__card__balance__balance'>
                ${formatNumberWithCommas(props.balance)}
              </div>
              <div className='overview-page__card__balance__commission'>
                Pending commissions: {commission}
              </div>
              <Button
                white small
                className='main-sidebar__item__aff-btn'
                onClick={() => props.toggleModal(true)}
                disabled={!canWithdraw}
              >
                <img src={withdrawIcon} alt='copy affiliate link'/>
                Request Withdrawal
              </Button>
            </div>
          )}

        />
        <InfoCard
          className='overview-page__card overview-page__partner-levels'
          customContent={(
            <div className='overview-page__partner-levels__content'>
              <div className='overview-page__partner-levels__row-container'>
                <div className='overview-page__partner-levels__row'>
                  <div className='overview-page__partner-levels__col'>
                    <div className='overview-page__partner-levels__title-row'>
                      <div className='overview-page__partner-levels__title'>
                        {level?.current?.name ? `Partner ${level.current.name} ` : ''}
                      </div>
                      <div
                        className='overview-page__partner-levels__info'
                        onMouseEnter={() => setOpenTooltip(true)}
                        onMouseLeave={() => setOpenTooltip(false)}
                      >
                        <img
                          src={questionMark}
                          alt='More info on partner levels'
                        />
                        <LevelsTooltip levels={partnerLevels} currentLevelSlug={level?.current?.slug}
                                       numLevel={numLevel} openTooltip={openTooltip}/>
                      </div>
                    </div>
                    <div className='overview-page__partner-levels__amount'>
                      {'$' + formatNumberWithCommas(totalReferred)}
                      {numLevel < 4 &&
                        <span>&nbsp;/&nbsp;{levelObj.maxAmount}</span>}
                    </div>
                    <div className='overview-page__partner-levels__desc'>
                      {`${level?.current?.text || ''}`}
                    </div>
                    <div className='overview-page__partner-levels__progress-bar'>
                      <div
                        className='overview-page__partner-levels__progress-bar__progress'
                        style={{ width: levelObj.progressBar }}
                      />
                    </div>
                  </div>
                </div>

              </div>

            </div>
          )}
        />
      </div>
    </div>
  )
}

OverviewHero.propTypes = {
  balance: PropTypes.string,
  loadBalance: PropTypes.func,
  toggleModal: PropTypes.func,
}

const mapStateToProps = ({ referrals: { withdrawals } }) => ({
  balance: withdrawals.balance,
})
const mapDispatchToProps = dispatch => ({
  loadBalance: () => dispatch(loadBalance()),
  toggleModal: open => dispatch(toggleAffiliateModal(open)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OverviewHero)
