import React from 'react'
import { Helmet } from 'react-helmet'

import ContentPadding from '@components/ContentPadding/index.jsx'
import ExampleSection from '@pages/Partners/Partner/Resources/Example.jsx'
import AddBanner from '@pages/Partners/Partner/Resources/Add.jsx'
import DownloadBanners from '@pages/Partners/Partner/Resources/banners.jsx'

import './Resources.scss'

export default function ResourcesPage () {
  return (
    <>
      <Helmet>
        <title>Resources</title>
      </Helmet>

      <ContentPadding>
        <div className='p-resources-page'>
          <AddBanner />
          <DownloadBanners />
          <ExampleSection />
        </div>
      </ContentPadding>
    </>
  )
}
