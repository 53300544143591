import React, { useEffect, useState } from 'react'
// import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useLocation, matchPath } from 'react-router-dom'

import { changeWorkspace } from '@redux/account'

// Auth
import AuthLayout from '@layout/Auth'
import PartnersLogin from '@pages/Auth/Login'
import PartnersSignUp from '@pages/Auth/SignUp'
import PartnersResetPassword from '@pages/Auth/ResetPassword'
import PartnersNewPassword from '@pages/Auth/NewPassword'
import WorkspaceInvite from '@pages/Auth/WorkspaceInvite/index.jsx'
// --

// Admin
import PartnerAdminLayout from '@layout/Admin'
import AdminDashboard from '@pages/Partners/Admin/Dashboard'
import PartnersList from '@pages/Partners/Admin/Partners'
import PartnerView from '@pages/Partners/Admin/Partner'
import ReferralPrograms from '@pages/Partners/Admin/ReferralPrograms'
import ProgramSettings from '@pages/Partners/Admin/ProgramSettings'
import AdminConversions from '@pages/Partners/Admin/Conversions/List'
import AdminReferralList from '@pages/Partners/Admin/Referrals/List'
import AdminWithdrawals from '@pages/Partners/Admin/Withdrawals'
import AdminClicksPage from '@pages/Partners/Admin/Clicks'
import ThemeList from '@pages/Partners/Admin/Themes/List'
import ReviewList from '@pages/Partners/Admin/Reviews/List'
import QuestionsList from '@pages/Partners/Admin/Themes/Questions/List'
import Question from '@pages/Partners/Admin/Themes/Questions/Question'
import ThemeView from '@pages/Partners/Admin/Themes/View.jsx'
import Review from '@pages/Partners/Admin/Reviews/Review'
import StoreList from '@pages/Partners/Admin/Stores/List'
import DeveloperList from '@pages/Partners/Admin/Developers/List'
import AppList from '@pages/Partners/Admin/Apps/List'
import AppView from '@pages/Partners/Admin/Apps/View'
import AppReviewsList from '@pages/Partners/Admin/Apps/Reviews/List'
import AppReview from '@pages/Partners/Admin/Apps/Reviews/Review'
import AppQuestionsList from '@pages/Partners/Admin/Apps/Questions/List'
import AppQuestion from '@pages/Partners/Admin/Apps/Questions/Question'
import AppCategoriesList from '@pages/Partners/Admin/Apps/Categories/List'
import AppProfileClaimsList from '@pages/Partners/Admin/Apps/ProfileClaims/List'

// --

// Partner
import PartnerLayout from '@layout/Partner'
import PartnerSettings from '@pages/Partners/Partner/Settings/index.jsx'
import FiltersList from '@pages/Partners/Admin/Filters/List.jsx'
import FilterGroupList from '@pages/Partners/Admin/Filters/FilterGroups'
import ReferralsPage from '@pages/Partners/Partner/Referrals/index.jsx'
import ThemesPage from '@pages/Partners/Partner/Themes/index.jsx'
import OverviewPage from '@pages/Partners/Partner/Overview/index.jsx'
import ThemePage from '@pages/Partners/Partner/Theme/index.jsx'
import ResourcesPage from '@pages/Partners/Partner/Resources/index.jsx'
import AppsPage from '@pages/Partners/Partner/Apps/index.jsx'
import AppPage from '@pages/Partners/Partner/App/index.jsx'
// --

// Submit Task
import SubmitProjectPage from '@pages/SubmitProject/index.jsx'
import SignupLayout from '@layout/Auth/SignUp.jsx'
// --

export default function AppLayout () {
  const [ready, setReady] = useState(false)
  const [admin, setAdmin] = useState(false)

  const [
    user,
    workspace,
    workspaces,
    isLoggedIn
  ] = useSelector(({ account }) => [
    account.user,
    account.workspace,
    account.workspaces,
    account.isLoggedIn
  ])

  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    toggleBackground(true)

    return () => {
      toggleBackground(false)
    }
  }, [])

  useEffect(() => {
    setReady(false)

    checkWorkspace()
  }, [user?.id, workspace?.id, isLoggedIn])

  function changeCurrentWorkspace (workspace) {
    dispatch(changeWorkspace({ workspace }))
  }

  function checkWorkspace () {
    // find first workspace with partner access
    if (!workspace?.id) {
      const firstWs = hasPartnerAccess()

      // if there is no workspace with partner access, redirect to /login
      if (!firstWs) {
        return needSignIn()
      }

      // change workspace
      return changeCurrentWorkspace(firstWs)
    }

    if (workspace.type === 'referral_account' && workspace.scopes.includes('account:read')) {
      // if workspace is referral account, check if user has account:read scope
      setReady(true)
      setAdmin(true)

      return
    } else if (workspace.type === 'partner' && workspace.scopes.includes('partner:read')) {
      // if workspace is partner, check if user has partner:read scope
      setReady(true)
      setAdmin(false)

      return
    }

    needSignIn()
  }

  function hasPartnerAccess () {
    return workspaces.find(ws => ws.type === 'partner' || ws.type === 'referral_account')
  }

  function needSignIn () {
    const atAuth = (
      matchPath({ path: '/login' }, location.pathname) ||
      matchPath({ path: '/sign-up' }, location.pathname) ||
      matchPath({ path: '/reset-password' }, location.pathname) ||
      matchPath({ path: '/reset-password/new' }, location.pathname) ||
      matchPath({ path: '/auth/accept-invite' }, location.pathname)
    )
    const publicPages = (
      matchPath({ path: '/submit-project/:ref_code' }, location.pathname)
    )

    if (!atAuth && !publicPages) {
      window.location.href = '/login'
    }
  }

  function toggleBackground (toggle) {
    window.document.body.classList.toggle('partner--layout', toggle)
    window.document.body.classList.toggle('partner--layout-local', import.meta.env.VITE_APP_ENV === 'local')
  }

  return (
    <Routes>
      <Route element={<AuthLayout />}>
        <Route exact path='login' element={<PartnersLogin />} />
        <Route exact path='reset-password' element={<PartnersResetPassword />} />
        <Route exact path='reset-password/new' element={<PartnersNewPassword />} />
        <Route exact path='auth/accept-invite' element={<WorkspaceInvite />} />
      </Route>

      <Route element={<SignupLayout />}>
        <Route exact path='sign-up' element={<PartnersSignUp />} />
      </Route>
      <Route exact path='/submit-project/:ref_code' element={<SubmitProjectPage />} />

      {ready && admin && (
        <Route element={<PartnerAdminLayout />}>
          <Route exact index element={<AdminDashboard />} />
          <Route exact path='partners' element={<PartnersList />} />
          <Route exact path='conversions' element={<AdminConversions />} />
          <Route exact path='clicks' element={<AdminClicksPage />} />
          <Route exact path='referrals' element={<AdminReferralList />} />
          <Route exact path='withdrawals' element={<AdminWithdrawals />} />
          <Route exact path='partners/v/:partnerSlug' element={<PartnerView />} />
          <Route exact path='partners' element={<PartnersList />} />
          <Route exact path='referral-programs' element={<ReferralPrograms />} />
          <Route exact path='program-settings/add' element={<ProgramSettings />} />
          <Route exact path='program-settings/1/edit' element={<ProgramSettings />} />
          <Route exact path='themes' element={<ThemeList />} />
          <Route exact path='themes/:id' element={<ThemeView />} />
          <Route exact path='theme-filters' element={<FiltersList />} />
          <Route exact path='theme-filter-groups' element={<FilterGroupList />} />
          <Route exact path='theme-reviews' element={<ReviewList />} />
          <Route exact path='theme-reviews/:id' element={<Review />} />
          <Route exact path='theme-questions' element={<QuestionsList />} />
          <Route exact path='theme-questions/:id' element={<Question />} />
          <Route exact path='developers' element={<DeveloperList />} />
          <Route exact path='theme-stores' element={<StoreList />} />
          <Route exact path='apps/:id' element={<AppView />} />
          <Route exact path='apps' element={<AppList />} />
          <Route exact path='app-reviews' element={<AppReviewsList />} />
          <Route exact path='app-reviews/:id' element={<AppReview />} />
          <Route exact path='app-questions' element={<AppQuestionsList />} />
          <Route exact path='app-questions/:id' element={<AppQuestion />} />
          <Route exact path='app-categories' element={<AppCategoriesList />} />
          <Route exact path='app-profile-claims' element={<AppProfileClaimsList />} />
        </Route>
      )}

      {ready && !admin && (
        <Route element={<PartnerLayout />}>
          <Route exact index element={<OverviewPage />} />
          <Route exact path='settings' element={<PartnerSettings />} />
          <Route exact path='reviews' element={<ThemesPage />} />
          <Route exact path='reviews/:id/*' element={<ThemePage />} />
          <Route exact path='app-reviews' element={<AppsPage />} />
          <Route exact path='app-reviews/:id/*' element={<AppPage />} />
          <Route exact path='referrals' element={<ReferralsPage />} />
          <Route exact path='resources' element={<ResourcesPage />} />
        </Route>
      )}
    </Routes>
  )
}

AppLayout.propTypes = {}
