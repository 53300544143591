import React from 'react'
import partnerLogos from '@assets/images/shologo-formy-heycarson.svg'
import logo from '@assets/images/shologo-formy-heycarson.svg'

function SignUpLeftPanel (props) {
	return (
		<div className='partners-signup__left'>
      <div>
          <a href={import.meta.env.VITE_SITE_URL} rel='noopener'>
					<img
						className='partners-signup__left__logo'
						src={logo}
						alt='Shopexperts formaly heycarson'
					/>
				</a>
			</div>

			<div>
				<h1 className='partners-signup__left__title'>
					Let’s maximize your <span>customer loyalty!</span>
				</h1>
				<p className='partners-signup__left__desc'>
					Whether you build Shopify themes or run an agency, your clients may need help with tasks outside your focus or requiring extra resources. We're here to support you.
				</p>
			</div>
    </div>
	)
}

export default SignUpLeftPanel
