import React, { useEffect, useRef, useState } from 'react'
import { Link, matchPath, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'

import { toggle } from '@redux/sidebar.js'

import SidebarItem from '@components/Sidebar/Item.jsx'
import Sidebar from '@components/Sidebar/index.jsx'
import Button from '@components/Button/index.jsx'

import overview from '@assets/icons/overview.svg'
import referral from '@assets/icons/conversions.svg'
import overviewPurple from '@assets/icons/overview.svg'
import referralPurple from '@assets/icons/conversions.svg'
import resources from '@assets/icons/resources.svg'
import resourcesPurple from '@assets/icons/resources.svg'
import copyLink from '@assets/icons/copy-link.svg'

import './Sidebar.scss'

export default function PartnerSidebar () {
  const location = useLocation()
  const [refCode, setRefCode] = useState(null)

  const [
    showSidebar,
    workspace,
    refCodes
  ] = useSelector(({ sidebar, account, referrals: { refCodes } }) => [
    sidebar.show,
    account.workspace,
    refCodes.data
  ])
  const [copied, setCopied] = useState(false)

  const themeAccess = (workspace?.scopes || []).includes('partner:themes:read')
  const appAccess = (workspace?.scopes || []).includes('partner:apps:read')

  const copiedTimeout = useRef(null)

  const dispatch = useDispatch()
  const toastr = useToastr()

  useEffect(() => {
    if (!refCodes.length) {
      return
    }

    setRefCode(refCodes[0]?.custom_code)
  }, [refCodes?.length])

  async function handleClickRefLink () {
    clearTimeout(copiedTimeout.current)

    const refLink = `${import.meta.env.VITE_SITE_URL}/client/new-project/?ref=${refCodes[0]?.custom_code}`
    try {
      if (window.navigator.clipboard) {
        await window.navigator.clipboard.writeText(refLink)
      } else {
        const tempInput = document.createElement("input");
        tempInput.value = refLink;
        document.body.appendChild(tempInput);
        tempInput.select();
        document.execCommand("copy");
        document.body.removeChild(tempInput);
        console.log('copied using input')
      }

      setCopied(true)
      toastr({
        type: TOASTR_SUCCESS,
        title: 'Success',
        message: 'Affiliate link copied to clipboard'
      })

      copiedTimeout.current = setTimeout(() => {
        setCopied(false)
      }, 500)
    } catch (error) {
      console.log('navigator error', error)
    }
  }

  const homeActive = matchPath({ path: '/', end: true }, location.pathname)
  const referralsActive = matchPath({ path: '/referrals', end: true }, location.pathname)
  const reviewsActive = matchPath({ path: '/reviews', end: true }, location.pathname)
  const appReviewsActive = matchPath({ path: '/app-reviews', end: true }, location.pathname)
  const resourceActive = matchPath({ path: '/resources', end: true }, location.pathname)

  return (
    <Sidebar
      open={showSidebar}
      onClose={() => dispatch(toggle(false))}
      showHelp
      helpUrl='mailto:partners@shopexperts.com'
    >
      {refCode && (
        <div className='main-sidebar__partner-submit-project'>
          <Button
            expanded dark small
            className='main-sidebar__item__aff-btn'
            onClick={handleClickRefLink}
          >
            <img src={copyLink} alt='copy affiliate link' />
            Copy Affiliate Link
          </Button>
        </div>
      )}

      <SidebarItem
        to='/'
        active={homeActive}
        onClick={() => dispatch(toggle(false))}
      >
        <img
          src={homeActive ? overviewPurple : overview}
          alt='overview' className='main-sidebar__item__icon'
        />
        Overview
      </SidebarItem>
      <SidebarItem
        to='/referrals'
        active={referralsActive}
        onClick={() => dispatch(toggle(false))}
      >
        <img
          src={referralsActive ? referralPurple : referral}
          alt='referral' className='main-sidebar__item__icon'
        />
        Conversions
      </SidebarItem>
      <SidebarItem
        to='/resources'
        active={resourceActive}
        onClick={() => dispatch(toggle(false))}
      >
        <img
          src={resourceActive ? resourcesPurple : resources}
          alt='referral' className='main-sidebar__item__icon'
        />
        Resources
      </SidebarItem>
    </Sidebar>
  )
}
