import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useBackToLogin, useBackToWebsite } from '@layout/Auth/hooks.js'
import { TOASTR_DANGER, TOASTR_SUCCESS, useToastr } from '@hooks/toastr.js'
import { resetPassword } from '@redux/account.js'

import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

import './NewPassword.scss'

function PartnersNewPassword () {
  const [, setBackToWebsite] = useBackToWebsite()
  const [, setBackLogin] = useBackToLogin()
  const [password, setPassword] = useState('')
  const [errors, setErrors] = useState({})
  const [confirmPassword, setConfirmPassword] = useState('')

  const [loading] = useSelector(({ account }) => [account.authPending])

  const [params] = useSearchParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const toastr = useToastr()

  const token = params.get('token')

  useEffect(() => {
    if (!token) {
      navigate('/password-reset')
    }
  }, [token])

  useEffect(() => {
    setBackLogin(true)
    setBackToWebsite(false)

    return () => {
      setPassword('')
      setConfirmPassword('')
    }
  }, [])

  function handleSubmit (event) {
    event.preventDefault && event.preventDefault()

    if (!token) {
      return
    }

    dispatch(resetPassword({
      token: decodeURIComponent(token),
      password,
      confirmPassword
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success.',
          message: 'Your password has been changed successfully.'
        })

        navigate('/login')
      })
      .catch(error => {
        let message = 'Please try again later.'

        if (
          error?.response?.status === 400 &&
          error?.response?.data?.params
        ) {
          message = error.response.data.message

          setErrors(error.response.data.params)
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message
        })
      })
  }

  return (
    <div className='new-password'>
      <div className='new-password__header'>
        <h1 className='new-password__title'>
          Password reset
        </h1>
        <h3 className='new-password__desc'>
          Pick a strong password for your partner account.
        </h3>
        <form
          action='#'
          className='new-password__form'
          onSubmit={handleSubmit}
        >
          <div className='new-password__form-group'>
            <div>
              <label className='new-password__label'>Create new password</label>
            </div>
            <TextInput
              small
              placeholder='Enter your password'
              type='password'
              value={password}
              onChange={event => setPassword(event.target.value)}
              error={errors.password}
              subText={errors.password}
            />
          </div>
          <div className='new-password__form-group'>
            <div>
              <label className='new-password__label'>Confirm new password</label>
            </div>
            <TextInput
              placeholder='Retype your password'
              type='password'
              small
              value={confirmPassword}
              onChange={event => setConfirmPassword(event.target.value)}
              error={errors.confirm_password}
              subText={errors.confirm_password}
            />
          </div>
          <Button
            dark
            small
            type='submit' primary expanded
            className='new-password__reset-password'
            disabled={loading}
          >
            Reset password
          </Button>
        </form>
      </div>
    </div>
  )
}

export default PartnersNewPassword
