import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { TOASTR_DANGER, TOASTR_SUCCESS, TOASTR_WARNING, useToastr } from '@hooks/toastr.js'
import { useBackToWebsite } from '@layout/Auth/hooks.js'
import { signUp } from '@redux/account.js'

import TextInput from '@components/Inputs/TextInput.jsx'
import Button from '@components/Button/index.jsx'

import './signup.scss'

import SignupSubmitted from '@layout/Auth/SignupSubmitted.jsx'

const initialFields = {
  companyUrl: '',
  companyName: '',
  email: '',
  password: '',
  name: ''
}

const getGrecaptcha = () => window && window.grecaptcha

function SignUpRightPanel () {
  const [fields, setFields] = useState(initialFields)
  const [errors, setErrors] = useState({})
  const [submitted, setSubmitted] = useState(false)

  // RECAPTCHA SPECIFIC
  const [renderCaptcha, setRenderCaptcha] = useState(false)
  const [token, setToken] = useState(null)
  const recaptchaRef = useRef()
  // --

  const [loading] = useSelector(({ account }) => [account.authPending])
  const dispatch = useDispatch()
  useNavigate()

  const toastr = useToastr()
  const [, setBackToWebsite] = useBackToWebsite()

  useEffect(() => {
    setBackToWebsite(true)
  }, [])

  // RECAPTCHA SPECIFIC
  useEffect(() => {
    setRenderCaptcha(true)

    return () => {
      setRenderCaptcha(false)
    }
  }, [])

  useEffect(() => {
    const g = getGrecaptcha()

    if (!renderCaptcha || !g) {
      return
    }

    g.ready(() => {
      g.render(recaptchaRef.current, {
        sitekey: import.meta.env.VITE_GOOGLE_RECAPTCHA_SITE_KEY,
        theme: 'light',
        size: 'invisible',
        callback: handleReCaptcha,
        badge: 'bottomleft'
      })
    })

    return () => {
      // reset to remove after unmount or element change
      try {
        g.reset()
      } catch (e) {
        // ignore
      }
    }
  }, [renderCaptcha])

  useEffect(() => {
    handleSubmit(token)
  }, [token])

  function handleReCaptcha (token) {
    if (!token) {
      return
    }

    setToken(token)
  }

  function execRecaptcha (event) {
    event?.preventDefault && event.preventDefault()

    if (renderCaptcha && getGrecaptcha()) {
      getGrecaptcha().execute()
      return
    }

    toastr({
      type: TOASTR_WARNING,
      title: 'ReCaptcha failed to load',
      message: 'please try again in a few seconds or refresh the page...'
    })
  }
  // --

  const handleSubmit = token => {
    if (!token) {
      return
    }

    dispatch(signUp({
      gRecaptchaToken: token,

      name: fields.name,
      email: fields.email,
      password: fields.password,

      company_url: fields.companyUrl,
      company_name: fields.companyName
    }))
      .then(() => {
        toastr({
          type: TOASTR_SUCCESS,
          title: 'Success',
          message: 'You have successfully registered.'
        })

        setSubmitted(true)
        setBackToWebsite(false)
      })
      .catch(error => {
        const g = getGrecaptcha()
        if (g) {
          g.reset()
          setToken(null)
        }

        let message = 'Please try again later.'

        if (
          error?.response?.status === 400 &&
          // eslint-disable-next-line no-tabs
					error?.response?.data?.code
        ) {
          if (
            error?.response?.data?.code === 'invalid_parameters'
          ) {
            const params = error.response.data.params
            message = error.response.data.message

            setErrors({
              name: params.user_name || undefined,
              email: params.email || undefined,
              password: params.password || undefined,
              companyUrl: params.company_url || undefined,
              companyName: params.company_name || undefined
            })
          } else if (
            error?.response?.data?.code === 'user_already_exist'
          ) {
            message = error.response.data.message

            setErrors({
              email: error.response.data.message
            })
          }
        }

        toastr({
          type: TOASTR_DANGER,
          title: 'Something went wrong.',
          message
        })
      })
  }
  const signupDesc = 'Our goal is to grow together, while helping mutual clients reach their goals.'
  const submitDesc = 'Please check your inbox for our email with further instructions.'

  return (
    <div className='partners-signup__right'>
      <div className='partners-signup__right__top'>
        Step {submitted ? 2 : 1}/2
      </div>
      <h1 className='partners-signup__right__title'>
        {submitted ? 'Sign up complete! ' : 'Register as a partner'}
      </h1>
      <p className='partners-signup__right__desc'>
        {submitted ? submitDesc : signupDesc}
      </p>
      {submitted && (
        <SignupSubmitted />
      )}

      {!submitted && (
        <>

          <h3 className='partners-signup__right__sign'>
            Already have a partner account?
            <Link to='/login'> Log in</Link>
          </h3>

          <form
            action='#'
            onSubmit={execRecaptcha}
            className='partners-signup__right__form'
          >
            <div className='partners-signup__right__form-group'>
              <TextInput
                label='Business name'
                placeholder='Your Company'
                type='text'
                small
                value={fields.companyName}
                onChange={event => setFields({
                  ...fields,
                  companyName: event.target.value
                })}
                error={errors.companyName}
                subText={errors.companyName}
              />
            </div>

            <div className='partners-signup__right__form-group'>
              <TextInput
                label='Business website'
                placeholder='yourcompany.com'
                type='text'
                small
                value={fields.companyUrl}
                onChange={event => setFields({
                  ...fields,
                  companyUrl: event.target.value
                })}
                error={errors.companyUrl}
                subText={errors.companyUrl}
              />
            </div>

            <div className='partners-signup__right__form-group'>
              <TextInput
                label='Your Name'
                placeholder='Please enter your full name'
                type='text'
                small
                value={fields.name}
                onChange={event => setFields({
                  ...fields,
                  name: event.target.value
                })}
                error={errors.name}
                subText={errors.name}
              />
            </div>

            <div className='partners-signup__right__form-group'>
              <TextInput
                small
                label='Business email address'
                placeholder='your@company.com'
                type='email'
                value={fields.email}
                onChange={event => setFields({
                  ...fields,
                  email: event.target.value
                })}
                error={errors.email}
                subText={errors.email}
              />
              <p className='partners-signup__right__use'>
                You will use this email address to log in to your account later.
              </p>
            </div>

            <Button
              type='submit'
              dark
              small
              expanded
              className='partners-signup__right__button'
              disabled={loading}
            >
              Sign up
            </Button>
          </form>

          <p className='partners-signup__right__question'>
            Have any questions or ideas to discuss? Send us an email&nbsp;
            <a target='_blank' rel='noopener noreferrer' href='mailto:partners@shopexperts.com'>here.</a>
          </p>
        </>
      )}

      <div ref={recaptchaRef} />
    </div>
  )
}

export default SignUpRightPanel
